/* eslint-disable no-magic-numbers */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Glide from '@glidejs/glide';

// Elements
import { RotatingWrapper, Button } from './Layout.styled';

class CategoryRotatingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: props.categories,
      categoryLink: props.categoryLink
    };
  }

  componentWillMount() {
  }

  componentDidMount() {
    const maxWidth = 767;
    const mobileSmallWidth = 280;
    const smallMobile = 2;
    const mobileImages = 3;
    const tabletImages = 4;

    let imagesToshow;

    if (window.screen.width <= mobileSmallWidth) {
      imagesToshow = smallMobile;
    } else if (window.screen.width > mobileSmallWidth && window.screen.width <= maxWidth) {
      imagesToshow = mobileImages;
    } else {
      imagesToshow = tabletImages;
    }

    const glide = new Glide('.glideRotatingImages', {
      type: 'carousel',
      focusAt: 0,
      perTouch: 1,
      perView: imagesToshow,
      touchRatio: 1,
      startAt: 0,
      perSwipe: '|',
      rewind: false,
      autoplay: 4000
    });
    glide.mount();
  }

  render() {
    return (
      <div>
        <div className="generic-slideshow generic-glide-style">
          <div data-glide-el="track" className="glide__track">
            <ul className="glide__slides">
              {
                this.state.categories.map((category) => (
                  <RotatingWrapper length={this.state.categories.length}>
                    <li className="glide__slide slider__frame">
                      <Button href={this.state.categoryLink + category.code} key={category.code} {...category} />
                    </li>
                  </RotatingWrapper>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

CategoryRotatingContainer.propTypes = {
  categories: PropTypes.any,
  categoryLink: PropTypes.string.isRequired
};

export default CategoryRotatingContainer;
