/* eslint-disable no-magic-numbers */

// Modules
import PropTypes from 'prop-types';
import Styled, { css, keyframes } from 'styled-components';
import { darken } from 'polished';

// Theme & Configs
import { colors, spacing } from '../theme';

// Animations
const MoveBG = frames => keyframes`
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -${frames - 1}00%;
  }
`;
const AnimateBG = frames => css`
  animation: ${MoveBG(frames)} 350ms steps(${frames - 1}) infinite;
`;

// Elements
const Button = Styled.a`
  width: 100%;
  height: 260px;
  display: inline-flex;
  text-decoration: none;
  flex-direction: column;
  color: ${colors.white} !important;
  background-color: ${props => props.color || colors.gray};
  font-family: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  transition: background-color 100ms linear;

  @media (max-width: 1920px) {
    max-height: 200px;
  }

  &:before {
    content: '';
    width: 100px;
    height: 100px;
    display: block;
    background-position: 0 0;
    background-size: auto 100%;
    margin: auto auto ${spacing.noSpace};
    ${props => props.image && `
      background-image: url('${props.image}');
    `}
    ${props => props.frames && !props.loop && `
      transition: background-position 100ms steps(${props.frames - 1});
    `}
  }
  &:after {
    content: '${props => props.name || 'Category'}';
    display: block;
    font-size: 19px;
    color: inherit !important;
    text-transform: capitalize;
    margin: ${spacing.m} auto auto;
    text-align: center;
  }

  &:focus,
  &:hover {
    background-color: ${props => darken(0.1, props.color || colors.gray)};

    ${({ frames, loop }) => frames && css`
      &:before {
        ${loop ? AnimateBG(frames) : `background-position-x: -${frames - 1}00%;`}
      }
    `}
  }
`;

const RotatingWrapper = Styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  ${Button} {
    width: ${() => `calc( ${100}% )`};
  }
`;

const Wrapper = Styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: ${spacing.xxl} ${spacing.s};

  ${Button} {
    width: ${props => `calc( ${100 / props.length}% -  ${spacing.number.l - (spacing.number.l / props.length)}px)`};
    
    &:not(:last-child) {
      margin-right: ${spacing.l};
    }

    @media (max-width: 1051px) {
      margin-bottom: ${spacing.l};
      width: calc( 33.333% -  ${spacing.number.l - (spacing.number.l / 3)}px);

    }
    @media (min-width: 601px) and (max-width: 1051px) {
      &:nth-of-type(3n){
        margin-right: ${spacing.noSpace};
      }
    }
    @media (max-width: 600px){
      width: calc( 50% -  ${spacing.number.l / 2}px);

      &:nth-of-type(2n) {
        margin-right: ${spacing.noSpace};
      }
    }
    @media (max-width: 351px) {
      width: 100%;
      height: 230px;
      margin-right: ${spacing.noSpace} !important;
    }
  }
`;

// Prop types
Button.propTypes = {
  href: PropTypes.string.isRequired,
  name: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.string,
  frames: PropTypes.number,
  loop: PropTypes.bool
};

Wrapper.propTypes = {
  length: PropTypes.number
};

// Exports
export {
  RotatingWrapper,
  Wrapper,
  Button
};
