// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Elements
import { Wrapper, Button } from './Layout.styled';

// Main Component
const CategoryButtons = ({ categories, categoryLink }) => (
  <Wrapper length={categories.length}>
    {categories.map(category => (
      <Button href={categoryLink + category.code} key={category.code} {...category} />)
    )}
  </Wrapper>
);

// Props
CategoryButtons.propTypes = {
  categories: PropTypes.array.isRequired,
  categoryLink: PropTypes.string.isRequired
};

// Export
export default CategoryButtons;
