// Spacing as number
const number = {
  noSpace: 0,
  xxxs: 2,
  xxs: 4,
  xs: 8,
  s: 12,
  m: 16,
  l: 24,
  xl: 32,
  xxl: 40,
  xxxl: 60,
  xxxxl: 100
};

// Exports
export default {
  noSpace: `${number.noSpace}px`,
  xxxs: `${number.xxxs}px`,
  xxs: `${number.xxs}px`,
  xs: `${number.xs}px`,
  s: `${number.s}px`,
  m: `${number.m}px`,
  l: `${number.l}px`,
  xl: `${number.xl}px`,
  xxl: `${number.xxl}px`,
  xxxl: `${number.xxxl}px`,
  xxxxl: `${number.xxxxl}px`,
  number
};
