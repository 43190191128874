// Modules
import React from 'react';
import { render } from 'react-dom';

// Configs
const CATEGORY_LINK = 'shop/';
const CATEGORIES = (window.categories || []).sort((a, b) => {
  if (a.position === b.position) {
    return 0;
  }
  return a.position > b.position ? 1 : -1;
});

// Components
import CategoriesButtons from './CategoriesButtons';
import CategoryRotatingContainer from './CategoryRotatingContainer';

const minDesktopResolution = 1280;

if (window.screen.width < minDesktopResolution) {
  const SlideShowContainer = () => (
    <div className="glideRotatingImages">
      <div className="row">
        <CategoryRotatingContainer categories={CATEGORIES} categoryLink={CATEGORY_LINK} />
      </div>
    </div>
  );
  render(<SlideShowContainer />, document.getElementById('category-button'));
} else {
  render(<CategoriesButtons categories={CATEGORIES} categoryLink={CATEGORY_LINK} />, document.getElementById('category-button'));
}
